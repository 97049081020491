import { css } from '@emotion/react';
import { styled, Typography, typographyClasses } from '@mui/joy';
import { Trip } from '../../../api-client-gen';
import { getNonWalkTripData } from './utils/tripHelper';

export default function TravelSegments({ trip }: { trip: Trip }) {
  const { segments } = getNonWalkTripData(trip);
  return (
    <Container>
      {segments.map(
        (
          {
            departureTime,
            origin,
            originPlatform,
            vehicleShort,
            destination,
            destinationPlatform,
            arrivalTime,
            direction,
          },
          index,
        ) => {
          const isLastSegment = index + 1 === segments.length;
          return (
            <Segment key={index}>
              <Line isFirst={index === 0}>
                <div className="line" />
                <div className="startBullet" />
                {isLastSegment && <div className="endBullet" />}
              </Line>
              <Typography style={{ gridArea: 'departureTime' }} fontWeight="lg">
                {departureTime}
              </Typography>
              <Typography style={{ gridArea: 'origin' }} fontWeight="lg">
                {origin}
              </Typography>
              {originPlatform && (
                <Typography style={{ gridArea: 'originPlatform' }} fontWeight="lg" textAlign="right">
                  {formatPlatform(originPlatform)}
                </Typography>
              )}
              <div style={{ gridArea: 'information' }}>
                <Typography fontSize="small">
                  <Typography variant="outlined">{vehicleShort}</Typography>
                  <Typography> Richtung {direction}</Typography>
                </Typography>
              </div>
              <Typography style={{ gridArea: 'arrivalTime' }} fontWeight={isLastSegment ? 'lg' : undefined}>
                {arrivalTime}
              </Typography>
              <Typography style={{ gridArea: 'destination' }} fontWeight={isLastSegment ? 'lg' : undefined}>
                {destination}
              </Typography>
              {destinationPlatform && (
                <Typography
                  style={{ gridArea: 'destinationPlatform' }}
                  fontWeight={isLastSegment ? 'lg' : undefined}
                  textAlign="right">
                  {formatPlatform(destinationPlatform)}
                </Typography>
              )}
            </Segment>
          );
        },
      )}
    </Container>
  );
}

function formatPlatform(platform: string) {
  if (/^[A-Z]$/.test(platform)) {
    return `Kante ${platform}`;
  }
  return `Gleis ${platform}`;
}

const Container = styled('div')`
  display: grid;
  gap: 1.2rem;
  .${typographyClasses.root} {
    color: var(--joy-palette-neutral-900);
  }
`;

const Segment = styled('div')`
  display: grid;
  grid-template-areas: 'departureTime line origin originPlatform' '. line information information' 'arrivalTime line destination destinationPlatform';
  grid-template-columns: 50px 10px 1fr auto;
  gap: 0.5rem;
`;

const Line = styled('div')<{ isFirst: boolean }>`
  grid-area: line;
  padding: 7px 0;
  display: grid;
  grid-template-rows: 10px 1fr auto;
  justify-items: center;
  > div {
    background: var(--custom-palette-pearl);
    &.line {
      grid-row: 2 / 3;
      width: 2px;
      margin: -2px 0;
    }
    &.startBullet {
      grid-row: 1 / 2;
      border-radius: 1000px;
      ${({ isFirst }) =>
        isFirst
          ? css`
              height: 10px;
              width: 10px;
            `
          : css`
              margin-top: 1px;
              height: 8px;
              width: 8px;
              border: 2px solid var(--custom-palette-pearl);
              background: transparent;
            `}
    }
    &.endBullet {
      grid-row: 3 / 4;
      height: 10px;
      width: 10px;
      border-radius: 1000px;
    }
  }
`;
